<template>
  <div>
    <basic-container>
        <el-dialog
            :title="$t('Add')"
            v-if="addVisible"
            :visible.sync="addVisible"
            width="70%"
            :append-to-body="true">
                   <el-collapse v-model="activeName">
                    <el-collapse-item title="规则基础信息" name="1">
                    <el-input v-model="name"  placeholder="请输入规则名称" size="small" style="width:150px;marginLeft:10px;"></el-input>
                    <el-input v-model="code"  placeholder="请输入规则编码" size="small" style="width:150px;marginLeft:10px;"></el-input>
                       
                    </el-collapse-item>
                    <el-collapse-item title="格式定义" name="2">
                        <avue-crud :option="tableOptions"
                :data="tableDatas"
                :table-loading="tableLoading"
                ref="crud"
                @row-del="showHandleDel">
            <template slot="menuLeft">
                  <el-popover
                        placement="bottom-start"
                        title="规则操作手册："
                        width="50%"
                        @show="showBro"
                        trigger="hover"
                        content="">
                <div class="popover-content" v-html="falg"></div>
                <el-button slot="reference" size="small" type="primary">规则说明</el-button>
            </el-popover>
                 </template>
        <template slot="menuRight">
         
             <el-select  v-model="type" placeholder="类型" size="small" hide="true" @change="typeChange" style="width: 150px !important; margin-right: 5px;marginLeft:10px;" filterable>
                 <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-input v-model="num" v-show='numShow' type="number" placeholder="请输入总数量" size="small" style="width:150px;marginLeft:10px;"></el-input>
             <el-select  v-model="data" v-show='dataShow' placeholder="时间格式" size="small" style="width: 150px !important; margin-left: 10px;" filterable>
                <el-option v-for="item in dataList" :key="item.value" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-input v-model="prefix" v-show='prefixShow' placeholder="请输入前缀" size="small" style="width:150px;marginLeft:10px;"></el-input>
            <el-input v-model="suffix" v-show='suffixShow' placeholder="请输入后缀" size="small" style="width:150px;marginLeft:10px;"></el-input>
            <el-input v-model="Spacer" v-show='spacerShow' placeholder="请输入间隔数" size="small" style="width:150px;marginLeft:10px;"></el-input>
            <el-input v-model="Separator" v-show='separatorShow' placeholder="请输入分隔符" size="small" style="width:150px;marginLeft:10px;"></el-input>
            <el-button type="primary" size="small" style="marginLeft:20px;" @click="save">添加</el-button>
        </template>
    </avue-crud>
                    </el-collapse-item>
             </el-collapse>
        
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="addVisible = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="addConfirm">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
            <template slot="menuLeft">
				<el-button size="small" @click="addBtn" type="primary">{{$t('ADD')}}</el-button>
             </template>
        <template slot="menuRight">
            <el-input v-model="nameOrIdLike" :placeholder="$t('Rule Name')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
            <el-input v-model="codeOrIdLike" :placeholder="$t('Rule Code')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
						<el-button size="small" @click="search" type="primary">{{$t('SEARCH')}}</el-button>
        </template>
        <template slot="menu" slot-scope='scope'>
            <el-button icon="el-icon-data-analysis" size="small" style="height:10px;" :type="scope.type" @click="goReport(scope.row)">{{$t('OPERATIONTYPE1')}}</el-button>
            <el-popover
                style="margin-left:10px;"
                placement="left-start"
                title="规则示例"
                width="200"
                @show="showBrowse(scope.row)"
                trigger="hover"
                :content="flag">
                <div class="popover-content" v-html="flag"></div>
            <el-button icon="el-icon-data-analysis" slot="reference" size="small" style="height:10px;" :type="scope.type" @click="goReport(scope.row)">浏览</el-button>

            </el-popover>

        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,addCodeRule,remove,generateRule,edit,getBrowse} from "@/api/customrules.js"
// import {list,changeStatus,add,edit,remove} from "@/api/timp"
    import { wklnList } from "@/api/config";
export default {
    data() {
        return {
            activeName:['1','2'],
            flag:'',
            falg: "",
            tableLoading: false,
            nameOrIdLike: "",
            codeOrIdLike: "",
            name:'',
            code:'',
            status: '0',
            zizengStr: '',
            dataStr: '',
            datanum:'',
            datazimu:'',
            ruleList: [],
            // 新增弹框的配置
            addVisible:false,// 新增规则弹出框
            typeList: [{id:'a',name:'字母'}
                        ,{id:1,name:'数字'}
                        ,{id:'i',name:'自增'}
                        ,{id:'t',name: '时间'}],
            dataList:[{id:1,name:'yyyyMMdd'}
                        ,{id:2,name:'yyyy/MM/dd'}
                        ,{id:3,name:'yyyy-MM-dd'}
                        ,{id:4,name:'yyyy/MM'}
                        ,{id:5,name:'yyyy-MM'}
                        ,{id:6,name:'yyyy'}
                        ,{id:7,name: 'yyyyMM'}],
            type:'a', //类型
            data:'', //日期
            num: '', //总数量
            prefix:'', // 前缀
            suffix:'', //后缀
            Separator:'',//分隔符
            Spacer: 0, //间隔符
            numShow:false, //总数量change
            dataShow:false, //日期change
            prefixShow: false,//前缀change
            suffixShow:false,//后缀change
            separatorShow:false, //分隔符change
            spacerShow:false,//间隔符change
            tableDatas:[],
            obj:{},
            tableOptions: {
                delBtn: true,
                addBtn:false,
                editBtn:false,
                menuWidth: 200,
                tip: false,
				refreshBtn: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: '类型',
                    prop: "type",
                    formatter: function (row) {
                        if(row.type == 1){
                            return '数字'
                        }
                        if(row.type == 'a'){
                            return '字母'
                        }
                        if(row.type == 'i'){
                            return '自增'
                        }
                        if(row.type == 't'){
                            return '时间'
                        }
                     }
                }, 
                {
                    label: '时间格式',
                    prop: "data",
                },
                {
                    label: '总数量',
                    prop: "count",
                },
                {
                    label: '前缀',
                    prop: "prefix",
                },
                {
                    label: '后缀',
                    prop: "suffix",
                },
                {
                    label: '分隔符',
                    prop: "Separator",
                },
                {
                    label: '间隔符',
                    prop: "Spacer",
                }]
            },
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            tableData: [],
            tableOption: {
                lazy: true,
                delBtn: true,
                addBtn:false,
                editBtn: false,
                menuWidth: 200,
                tip: false,
				refreshBtn: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                // editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                   label: this.$t('Rule Code'),
                    prop: "code",
                }, {
                     label: this.$t('Rule Name'),
                    prop: "name",
                },
                {
                    label: this.$t('Quantity Used'),
                    prop: "count",
                },
                {
                    label: this.$t('Maximum Capacity'),
                    prop: "maxCount",
                },
                {
                    label: this.$t('Unit Time'),
                    prop: "s_Status",
                    formatter: function (row) {
                        if(row.minTimespanType == -1){
                            return ''
                        }
                        if(row.minTimespanType == 0){
                            return '秒'
                        }
                        if(row.minTimespanType == 1){
                            return '分'
                        }
                        if(row.minTimespanType == 2){
                            return '小时'
                        }
                        if(row.minTimespanType == 3){
                            return '天'
                        }
                        if(row.minTimespanType == 4){
                            return '周'
                        }
                        if(row.minTimespanType == 5){
                            return '月'
                        }
                        if(row.minTimespanType == 6){
                            return '季度'
                        }
                        if(row.minTimespanType == 7){
                            return '年'
                        }

                     }
                 },
                {
                    label: this.$t('Current State'),
                    prop: "status",
                }]
            }
        }
    },
    created() {
      this.list();
    },
    methods: {
        showBro() {
            this.falg = "<div>"+ "1.此规则定义后该模块的数据字段默认为此规则格式，后续该模块的数据添加此字段无需进行填写且会根据此规则自动生成" +"</div>" + "<div>"+ "2.规则编码即为该规则名称所对应的代码，系统可通过该规则代码判断使用的规则格式" +"</div>" + 
            "<div>"+ "3.类型选择即为规则所支持的基本规则类型，每个规则类型有对应的格式类型 例如：时间类型 有yyyyMMdd、yyyy-MM-dd等格式，数字，字母，自增则没有格式类型" +"</div>" + "<div>"+ "4.用户选择完规则类型后根据所选择的类型在选择格式或者根据需求输入该规则类型产生的位数，前后缀除外，若选择自增则需要根据需求填写间隔的位数以及间隔符（可不填）" +"</div>"+ 
            "<div>"+ "5.根据需求定义好规则格式后点击保存 会产生一条规则格式，若需要多个规则格式叠加使用 则可以在确定前再次定义规则格式即可，规则格式即为由上向下拼接成该规则编码的格式" +"</div>" + "<div>"+ "6.点击确定后系统会根据该规则编码所对应的格式在对应的模块进行编码生成" +"</div>" +
            "<div>"+ "案例：" +"</div>" + "<div>"+ "第一条：规则名称：测试  规则编码：CS  类型：字母  规则位数：3   前缀：D  后缀：H" +"</div>" + 
             "<div>"+ "第二条：规则名称：测试  规则编码：CS  类型：时间  时间格式：yyyyMMdd   前缀：U  后缀：K" +"</div>" + "<div>"+ "规则格式样式：DVxPHU20211201K" +"</div>" 

        },
        showBrowse(row) {
            console.log(row);
           getBrowse(row.code).then(res => {
               let str = ''
               res.data.data.forEach(item => {
                    str += "<div>"+ item +"</div>"
               })
               console.log(str);
               this.flag = str
               console.log(res);
           })
        },
        clear() {
            this.type = '',
            this.data= '',
            this.num = '',
            this.prefix = '',
            this.suffix = '',
            this.Separator = '',
            this.Spacer = ''
        },
        search() {
            console.log(1111);
        },
        // 新增按钮
        addBtn() {
            this.addVisible = true
             this.typeChange('a')
            // this.type = 'a'
            // this.numShow = true
            // this.prefixShow = true
            // this.suffixShow = true
            // this.dataShow = false
            // this.separatorShow = false
            // this.spacerShow = false
        },
        // 弹出框确定
        addConfirm() {
            console.log(this.ruleList);
            let str = ''
            this.ruleList.forEach(function(item) {
                console.log(item);
                str = str + '&' + item
            });
            console.log('123',str);
            let str1 = str.substr(1); //删除首字符
            console.log('规则数组',str1);
            if (this.name == '' || this.code == '' ) {
               this.$message.error('规则名称,规则编码不能为空')
            } else {
                addCodeRule(this.name,this.code,str1).then(res => {
                    console.log('新的规则',res);
                    if (res.data.code == '0000') {
                        this.addVisible = false
                        this.$message.success('新增成功')
                    } else {
                        this.$message.success(res.data.msg)
                         this.addVisible = false
                    }
                    this.list()
                })
            }
        },
        handleChange(value) {
            console.log(value);
      },
      // 切换类型
        typeChange(row) {
            if(row == 'a') {
                this.numShow = true
                this.prefixShow = true
                this.suffixShow = true
                this.dataShow = false
                this.separatorShow = false
                this.spacerShow = false
            }
            if (row == 1) {
                this.numShow = true
                this.prefixShow = true
                this.suffixShow = true
                this.dataShow = false
                this.separatorShow = false
                this.spacerShow = false
            }
            if (row == 'i') {
                this.numShow = true
                this.separatorShow = true
                this.spacerShow = true
                this.prefixShow = false
                this.suffixShow = false
                this.dataShow = false
            }
            if (row == 't') {
                this.dataShow = true
                this.prefixShow = true
                this.suffixShow = true
                this.numShow = false
                this.separatorShow = false
                this.spacerShow = false
            }
            console.log(row);
        },
        // 弹出框保存
        save() {
            if (this.type == '') {
               this.$message.error('类型不能为空')
               return;
            }
            if ((this.type == 'a'||this.type == 1) && this.num == '') {
                this.$message.error('总数量不能为空')
                return;
            }
            if (this.type == 'i' && (this.num == '' || this.Spacer == '')) {
                this.$message.error('总数量和间隔符不能为空')
                return;
            }
             if (this.type == 't' && this.data == '') {
                this.$message.error('时间格式不能为空')
                return;
            }
            
            console.log(this.type);
            this.obj = {
                type: this.type,
                data:this.data,
                count:this.num,
                prefix:this.prefix,
                suffix:this.suffix,
                Separator:this.Separator,
                Spacer:this.Spacer
            }
            console.log('新增对象',this.obj);
            this.tableDatas.push(this.obj)
             if (this.type == 'i') {
                this.zizengStr = this.type + '^' + this.num + '^' + this.Spacer + '^' + this.Separator
                this.ruleList.push(this.zizengStr)
                console.log('自增',this.zizengStr);
                this.clear()
                return
            }
            if (this.type == 't') {
                this.dataStr = this.type + '^' + this.data + '^' + this.prefix + '%' + this.suffix
                this.ruleList.push(this.dataStr)
                // this.ruleList = this.ruleList + '&' + this.dataStr
                console.log('时间',this.dataStr);
                this.clear()
                return
            }if (this.type == 1) {
                this.datanum = this.type + '^' + this.num + '^' + this.prefix + '%' + this.suffix
                this.ruleList.push(this.datanum)
                // this.ruleList = this.ruleList + '&' + this.datanum
                console.log('数字',this.datanum);
                this.clear()
                return
            } if(this.type == 'a') {
                this.datazimu = this.type + '^' + this.num + '^' + this.prefix + '%' + this.suffix
                this.ruleList.push(this.datazimu)
                // this.ruleList = this.ruleList + '&' + this.datazimu
                console.log('字母',this.datazimu);
                this.clear()
                return
            }
           console.log('规则数组',this.ruleList);
        },
        //生成结果
        goReport(item) {
            console.log(item)
            generateRule(item.id).then(res => {
                if (res.data.code == '0000') {
                    this.$alert( res.data.data, '生成结果', {
                    confirmButtonText: '关闭',
                    });
                } else {
                    this.$message.error(res.data.msg)
                }
                 
                console.log(res);
            })
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
        handleUpdate() {
            console.log('编辑');
        },
        // 删除
        handleDel(row) {
            console.log(row);
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(res => {
                    console.log(res);
                    if(res.data.code == "0000") {
                        this.$message({
                            type: "success",
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg
                        });
                    }
                    this.list();
                })
            })
        },
        // 弹出层删除
        showHandleDel(row) {
            console.log(row);
            console.log(row.$index);
            this.tableDatas.splice(row.$index,1)
            this.ruleList.splice(row.$index,1)
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row, done) {
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            console.log(111,row);
            edit(row.id,null,null,null,row.status).then((res) => {
                console.log(res);
                this.$message({
                    message: "修改成功",
                    type: "success"
                });
            });
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.nameOrIdLike,this.codeOrIdLike,this.page.pageCurrent,this.page.pageSize).then(res => {
              console.log('res',res);
              this.tableData = res.data.data.data
              this.page.total = res.data.data.total;
              // this.tableData = res.data.data.items;
              // console.log('this.tableData',this.tableData);
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>